


















// CORE
import { Component, Vue, Prop } from 'vue-property-decorator'

// INTERFACES
import { NameValueResource } from '@/store/types'

@Component
export default class ServicesOrderView extends Vue {
  @Prop({ required: true })
  readonly title!: string

  @Prop({ default: null })
  readonly other!: string

  @Prop({ default: () => ([]) })
  readonly items!: NameValueResource[]
}
