





















































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'

import OrderCard from '@/components/cards/OrderCard.vue'
import CarOrderView from '@/components/orderViews/CarOrderView.vue'
import CharacterCard from '@/components/cards/CharacterCard.vue'
import ServicesOrderView from '@/components/orderViews/ServicesOrderView.vue'
import SmallDocumentCard from '@/components/cards/SmallDocumentCard.vue'

// STORE
import FleetOrderModule from '@/store/modules/fleet/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

// HELPERS
import { parseDate } from '@/utils/functions'
import { RequestTypeEnum } from '@/store/types'

@Component({
  components: {
    SmallDocumentCard,
    DefaultLayout,
    CharacterCard,
    CarOrderView,
    OrderCard,
    RequestFormPartWrapper,
    ServicesOrderView,
  },
})
export default class CarCrashOrderView extends Mixins(SystemMixin, NotifyMixin) {
  private get orderRequestParams () : { id: number, type: RequestTypeEnum } {
    const id = +this.$route.params.orderId
    const type = RequestTypeEnum.CRASH

    return { id, type }
  }

  private get order (): any {
    return FleetOrderModule.order
  }

  private created () {
    FleetOrderModule.getOrder(this.orderRequestParams)
      .catch(() => {
        this.notifyError('Заявка не найдена')
        this.$router.push({ name: 'orders' })
      })
  }

  private parseDate = parseDate
}
