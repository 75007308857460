


























import { Component, Prop, Mixins } from 'vue-property-decorator'

import Card from '@/components/cards/Card.vue'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  inheritAttrs: false,
  components: {
    Card,
  },
})
export default class SmallDocumentCard extends Mixins(NotifyMixin){
  @Prop({ required: true })
  private document!: any

  private get isShowDocumentInfo (): boolean {
    const isFile = this.document.file.type === 'file'
    const isImage = this.document.file.type === 'image'

    return isFile || isImage
  }

  private goToDocument (link: string){
    window.open(link, '_blank')
  }
}
