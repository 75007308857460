














































// CORE
import { Component, Vue, Prop } from 'vue-property-decorator'

// INTERFACES
import { ICarFormPart } from '@/store/types'

// STORE
import DriverOrderModule from '@/store/modules/driver/order'

@Component
export default class CarOrderView extends Vue {
  @Prop({ default: null })
  readonly id!: number

  @Prop({ default: false })
  readonly isAutoComplete!: boolean

  @Prop({ default: true })
  readonly isReadonly!: boolean

  @Prop({ default: '' })
  readonly currentMileage!: string

  @Prop({ default: () => ({
    vin: '',
    stateNumber: '',
    orderNumber: '',
    brand: '',
    model: '',
    currentMileage: '',
  }) })
  readonly data!: ICarFormPart

  private get innerData (): any {
    if (this.isAutoComplete) {
      return DriverOrderModule.car
    } else {
      return this.data
    }
  }

  private get stateNumber (): string {
    return this.innerData.stateNumber.slice(0, 6) + ' ' + this.innerData.stateNumber.slice(6)
  }

  private created () {
    if (this.isAutoComplete && this.id) {
      DriverOrderModule.getCarForId(this.id)
    }
  }
}
