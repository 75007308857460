
























































// CORE
import { Component, Prop, Mixins } from 'vue-property-decorator'

// HELPERS
import { parseDate } from '@/utils/functions'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// COMPONENTS
import Card from '@/components/cards/Card.vue'

// INTERFACES
import { RequestShortResource, StatusRequestEnum, UserRequestShortResource } from '@/store/types'

@Component({
  components: {
    Card,
  },
})

export default class OrderCard extends Mixins(NotifyMixin, SystemMixin){
  @Prop({ required: true })
  private card!: RequestShortResource

  @Prop({ default: false })
  private isShort!: boolean

  private statusRequestEnum = StatusRequestEnum

  private get statusObject (): { color: string, label: string } {
    switch (this.card.status) {
    case this.statusRequestEnum.PLACEMENT :
      return {
        color: 'orange',
        label: 'размещено',
      }
    case this.statusRequestEnum.IN_WORK :
      return {
        color: 'blue',
        label: 'в работе',
      }
    case this.statusRequestEnum.DONE :
      return {
        color: 'green',
        label: 'выполнено',
      }
    case this.statusRequestEnum.CANCEL :
      return {
        color: 'red',
        label: 'отменена',
      }
    default:
      return {
        color: 'red',
        label: 'ошибка',
      }
    }
  }

  private get cardRoute () {
    if (this.card) {
      return {
        name: `view-order.${this.card.type}`,
        params: {
          role: this.role,
          entity: this.entity,
          orderId: this.card.id,
        },
      }
    } else {
      return {}
    }
  }

  private get cardDate () {
    if (this.card) {
      return parseDate(`${this.card.date}`)
    } else {
      return ''
    }
  }

  private get cardUserType () {
    if (this.card) {
      return this.card.userType === 'manager' ? 'менеджера' : 'водителя'
    } else {
      return 'сотрудника'
    }
  }

  private get cardUser () {
    if (this.card) {
      return this.parseUserName(this.card.user)
    } else {
      return ''
    }
  }

  private get cardStatus () {
    if (this.card) {
      return this.card.status && this.card.status !== this.statusRequestEnum.SEND_MAIL
    } else {
      return false
    }
  }

  private parseDate = parseDate

  private parseUserName (user: UserRequestShortResource) {
    let result = ''

    if (user.name) {
      result += user.name + ' '
    }

    if (user.surname) {
      result += user.surname.slice(0,1) + '. '
    }

    if (user.patronymic) {
      result += user.patronymic.slice(0,1) + '.'
    }

    return result.trim() ? result.trim() : 'Нет данных'
  }
}
